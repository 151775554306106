
import { mapGetters, mapMutations } from "vuex";
import filters from "@/mixins/filters";
import seoTags from "@/apollo/queries/seoTags";

export default {
	mixins: [filters],
	data() {
		return {
			ready: false,
			masks: {
				yearInput: {
					suffix: " г.",
					// showMaskOnHover: false,
					// showMaskOnFocus: true,
					// oncomplete: () => this.$emit('phoneMaskComplete'),
					// onincomplete: () => this.$emit('onincomplete'),
					// definitions: {
					// 	"#": {
					// 		validator: "[1234569]",
					// 	},
					// },
					groupSeparator: "",
					alias: "numeric",
					// autoGroup: true,
					digits: 0,
					digitsOptional: false,
					clearMaskOnLostFocus: false,
					rightAlign: false,
					// max: Number(new Date().getFullYear())
				}
			},
			inputFrom: 0,
			inputTo: 0,
			min: 0,
			max: 0
		}
	},
	props: {
		from: String,
		to: String
	},

	computed: {
		...mapGetters({
			filters: 'filters/filters/filters',
			chosen: 'filters/filters/chosen',
			offers: 'filters/filters/offers',
		}),
		filterYear() {
			return this.filters.year || [0, 0]
		},
		getPriceOptions() {
			return {
				type: 'double',
				grid: false,
				realTime: true,
				step: 1,
				from: this.inputFrom,
				to: this.inputTo,
				min: Number(this.filterYear?.[0]),
				max: Number(this.filterYear?.[1]),
				drag_interval: true,
				min_interval: null,
				max_interval: null,
				prettify: this.my_prettify,
				onChange: (event) => {
					this.inputFrom = event.from
					this.inputTo = event.to
					this.setChosen({ key: 'yearFrom', value: this.inputFrom })
					this.setChosen({ key: 'yearTo', value: this.inputTo })
					// console.log(this.chosen.yearTo)
				},
				// onFinish: (event) => this.sendPrice(event),

			}
		},
		chosenYear() {
			return this.chosen ? this.chosen : 'asd'
		}
	},
	mounted() {
		if (this.chosen.yearFrom && this.chosen.yearTo) {
			this.inputFrom = Number(this.chosen.yearFrom)
			this.inputTo = Number(this.chosen.yearTo)
		} else {
			this.inputFrom = Number(this.filterYear?.[0])
			this.inputTo = Number(this.filterYear?.[1])
		}

		this.ready = true;
		// })
	},

	methods: {
		...mapMutations({
			setChosen: 'filters/filters/SET_CHOSEN',
		}),
		...mapMutations({
			setTabComponent: 'modal/modal-choose/SET_TAB_COMPONENT',
			setIsFilterClick: 'filters/filters/SET_IS_FILTER_CLICK',

		}),
		// inp(value, tag) {
		// 	tag === 'priceFrom' && this.$refs["range-year"].slider.update({ from: Number(String(value).replace('₽', '').split(' ').join('')) })
		// 	tag === 'priceTo' && this.$refs["range-year"].slider.update({ to: Number(String(value).replace('₽', '').split(' ').join('')) })

		// },
		async sendPrice() {
			this.setIsFilterClick(true)
			await this.$router.push({ path: this.$route.fullPath, query: { year_from: this.inputFrom, year_to: this.inputTo } });
		},
		my_prettify(n) {
			return n ? Number(String(n).replaceAll(' ', '')) : ''
		},
		inp(value, tag) {
			if (tag === 'yearFrom') {
				this.inputFrom = Number(value.replace(/[^+\d]/g, ''))
				this.$refs["range-year"].slider.update({
					from: value.replace(/\s/g, '').replace('г.', '')
				})
			}
			if (tag === 'yearTo') {
				this.inputTo = Number(value.replace(/[^+\d]/g, ''))
				this.$refs["range-year"].slider.update({
					to: value.replace(/\s/g, '').replace('г.', '')
				})
			}
			let inputFrom = this.inputFrom
			let inputTo = this.inputTo
			this.setChosen({ key: 'yearFrom', value: inputFrom })
			this.setChosen({ key: 'yearTo', value: inputTo })
		},

	},
	watch: {

		inputFrom(value) {
			if (value) {
				let im = new Inputmask(this.masks.yearInput);
				im.mask(this.$refs.inputLeft__year);
			}

		},
		inputTo(value) {
			if (value) {
				let im = new Inputmask(this.masks.yearInput);
				im.mask(this.$refs.inputRight__year);
			}
		},

		// filterYear(value) {
		// 	console.log('filterYear(value)', value)
		// 	if (this.inputFrom && (value[0] < Number(this.inputFrom))) this.inputFrom = value?.[0]
		// 	if (this.inputTo && (value[1] > Number(this.inputTo))) this.inputTo = value?.[1]

		// }


	},
	// destroyed() {
	// 	this.changeInputs()
	// },

}
